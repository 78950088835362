import { createDataActionHook, IActionInput } from "@msdyn365-commerce/core";

// tslint:disable no-any

const postReadOnlyProducts = async (
  _inputs: IActionInput | IActionInput[],
  products: any
) => {
  console.log(_inputs);
  console.log(products);
};

createDataActionHook({
  actionId:
    "@msdyn365-commerce-modules/search-result-container/get-full-products-by-collection",
  postReadonlyHook: postReadOnlyProducts
});
